import { computed } from 'vue';
import { useStore } from 'vuex';
import { isValidEmail } from '@/helpers/emails';
import { FEATURE_FLAGS } from '@/constants';
export function useSeatsValidations(params) {
    const { searchTerm, selectedUsers, seatsToBuy, workspace } = params;
    const isPricingEnabled = FEATURE_FLAGS.PRICING_ENABLED;
    const store = useStore();
    const userId = computed(() => store.getters.session.id);
    const seats = computed(() => workspace.value?.seats ?? 0);
    const members = computed(() => workspace.value?.members ?? []);
    const isFree = computed(() => workspace.value?.plan === 'free');
    const isWorkspaceOwner = computed(() => members.value.find((m) => m.id === userId.value)?.owner ?? false);
    const invitedEmails = computed(() => {
        const invitations = workspace.value?.invitations ?? [];
        const emails = invitations.map((i) => i.email);
        return Array.from(new Set(emails));
    });
    const workspaceEmails = computed(() => {
        return [...members.value.map((member) => member.email), ...invitedEmails.value];
    });
    const newInvitedUsersAsEmails = computed(() => {
        const existingEmails = new Set(workspaceEmails.value);
        const newEmails = selectedUsers.value.map((u) => u.email).filter((e) => !existingEmails.has(e));
        if (isValidEmail(searchTerm.value) && !existingEmails.has(searchTerm.value)) {
            newEmails.push(searchTerm.value);
        }
        return Array.from(new Set(newEmails));
    });
    const totalSeatsTaken = computed(() => workspaceEmails.value.length + newInvitedUsersAsEmails.value.length);
    const totalSeatsAvailable = computed(() => seats.value - totalSeatsTaken.value);
    const displayableSeatsTaken = computed(() => Math.min(seats.value, totalSeatsTaken.value));
    const displayableSeatsAvailable = computed(() => Math.max(0, totalSeatsAvailable.value));
    const requiredSeatsToBuy = computed(() => Math.abs(Math.min(0, totalSeatsAvailable.value)));
    const hasToBuySeats = computed(() => isPricingEnabled && !isFree.value && isWorkspaceOwner.value && requiredSeatsToBuy.value > 0);
    const isSeatsToBuyValid = computed(() => requiredSeatsToBuy.value <= seatsToBuy.value);
    return {
        requiredSeatsToBuy,
        totalSeatsAvailable,
        displayableSeatsAvailable,
        displayableSeatsTaken,
        hasToBuySeats,
        isSeatsToBuyValid,
        seats
    };
}
