import { isValidEmail } from '@/helpers/emails';
/**
 * Converts a search term into a user.
 *
 * - If the term is an email from an `User` in the users list, returns
 *   that `User`.
 * - If the term is a valid email, returns a `UnknownUser` with that email.
 * - Otherwise returns `null`.
 */
export function searchTermToUser(term, users) {
    const lowerCaseTerm = term.toLowerCase();
    const user = users.find((u) => u.email === lowerCaseTerm);
    if (user) {
        return user;
    }
    else if (isValidEmail(lowerCaseTerm)) {
        return { email: lowerCaseTerm };
    }
    else {
        return null;
    }
}
